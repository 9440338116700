<template>
  <base-layout>
    <IonSegment :value="activeDay" scrollable style="justify-content: flex-start">

      <IonSegmentButton v-for="(value, key) in days" :key="key" :value="key" style="min-width:250px" mode="md"
                        @click="activeDay = key">{{ value.label }}
      </IonSegmentButton>
    </IonSegment>
    <ion-content id="type-detail-page" class="has-header has-subheader">
      <h2>{{ type.name }}</h2>
      <session-list :sessions="days[activeDay].sessions"
                    v-if="days[activeDay] && days[activeDay].sessions"></session-list>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSegment, IonSegmentButton} from '@ionic/vue';
import {mapActions} from "vuex";
import SessionList from "@/components/SessionList"
import moment from "moment";

export default {
  data() {
    return {
      type: {},
      sessions: [],
      days: [],
      activeDay: '',
    };
  },
  components: {
    IonContent,
    IonSegment,
    IonSegmentButton,
    "session-list": SessionList
  },
  methods: {
    ...mapActions("sessiontypes", ["getType", "getSessionsByType"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),




    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHeader: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, D. MMMM')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },
  },
  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Sessiontype Detail') {
          this.type = await this.getType(this.$route.params.id);
          this.trackWithLabel(this.type.name)


          let sessions = (await this.getSessionsByType(this.type.id)).sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
          });

          this.sessions = this.groupList(sessions, 'start_time');
          this.days = {};
          this.prepareDays(this.sessions);
        }
      }
    }
  }
};
</script>
<style scoped>
#type-detail-page {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}
</style>
